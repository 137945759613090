<template>
  <div>
    <b-button class="mb-2" @click="$router.push('/campanhas/modelos')"
      >Voltar</b-button
    >
    <Card class="pt-2 pb-3 mb-2">
      <b-row>
        <b-col>
          <SelectChannel v-model="model.channel_id" :disabled="editing" />
        </b-col>
        <b-col>
          <SelectApplication
            v-model="model.application_id"
            :disabled="editing"
          />
        </b-col>
        <b-col>
          <label>Nome do Modelo</label>
          <b-input v-model="model.description"></b-input>
        </b-col>
      </b-row>
    </Card>
    
    <template v-if="editorReady">
      <b-row>
        <b-col>
          <TemplateSelectSlider
            :application_id="model.application_id"
            :notification_type_id="model.notification_type_id"
            :type="'header'"
            v-model="model.header_id"
          />
        </b-col>
        <b-col>
          <TemplateSelectSlider
            :application_id="model.application_id"
            :notification_type_id="model.notification_type_id"
            :type="'footer'"
            v-model="model.footer_id"
          />
        </b-col>
        <b-col>
          <BackgroundSelectSlider v-model="model.background_id" />
        </b-col>
      </b-row>
      <Card
        class="py-2 mt-2"
        v-if="editorReady && model.header_id && model.footer_id"
      >
        <b-row class="mb-2">
          <template>
            <b-col class="d-flex align-items-baseline">
              <ListTags
                v-if="optionalVariables.length > 0"
                :variables="optionalVariables"
                :element="'optionalVariables'"
                :title="'Tags Opcionais'"
              />
            </b-col>
            <b-col class="d-flex align-items-baseline">
              <ListTags
                v-if="requiredVariables.length > 0"
                :variables="requiredVariables"
                :element="'requiredVariables'"
                :title="'Tags Obrigatórias'"
              />
            </b-col>
            <b-button class="mr-2" @click="show_preview">Visualizar</b-button>
            <b-button @click="save_template">Salvar</b-button>
          </template>
        </b-row>

        <b-row>
          <b-col>
            <MailEditor
              :design="model.design"
              :background="model.background_id"
              ref="mailEditor"
            />
          </b-col>
        </b-row>
      </Card>
    </template>
    <ModalPreviewHtml ref="previewHtml" :previewHtml="previewHtml" />
    <ModalInvalidVariables ref="modalInvalidVariables" />
    <MailEditor
      ref="html_generator"
      :design="designFull"
      :background="model.background_id"
      style="display: none"
    />
    <SweetModal
      ref="modalError"
      overlay-theme="dark"
      hide-close-button
      icon="error"
    >
      Erro ao gravar template
    </SweetModal>
    <SweetModal
      ref="modalSuccess"
      overlay-theme="dark"
      hide-close-button
      icon="success"
    >
      Modelo salvo com Sucesso!
      <template #button>
        <b-button @click="$router.push('/campanhas/modelos')" variant="success"
          >Ok</b-button
        >
      </template>
    </SweetModal>
    <SweetModal
      blocking
      ref="modalLoading"
      overlay-theme="dark"
      hide-close-button
    >
      <sweetalert-icon icon="loading" />
      {{ modalLoadingMsg }}</SweetModal
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      editing: false,
      modalLoadingMsg: "",
      showEditor: false,
      previewHtml: "",
      designFull: [],
      model: {
        id: this.get_uuid(),
        application_id: "",
        channel_id: "",
        unity_id: this.$store.state.auth.currentUser.unity_id,
        notification_type_id: null,
        description: "",
        type: "content",
        variables: "",
        design: [],
        html: "",
        header_id: "",
        footer_id: "",
        background_id: "",
        legacy: "0",
        active: "1",
        default: "0",
      },
    };
  },
  components: {
    SelectChannel: () => import("@/components/SelectChannel"),
    SelectApplication: () => import("@/components/SelectApplication"),
    SelectNotificationType: () => import("@/components/SelectNotificationType"),
    TemplateSelectSlider: () => import("@/components/TemplateSelectSlider"),
    BackgroundSelectSlider: () => import("@/components/BackgroundSelectSlider"),
    MailEditor: () => import("@/components/EmailEditor"),
    ListTags: () => import("@/components/ListTags"),
    ModalPreviewHtml: () => import("@/components/ModalPreviewHtml"),
    ModalInvalidVariables: () => import("@/components/ModalInvalidVariables"),
    TableTemplatesList: () => import("./TableTemplatesList.vue"),
  },
  computed: {
    state() {
      return this.$store.state.global;
    },
    editorReady() {
      if (
        this.model.application_id !== "" &&
        this.model.description !== "" &&
        this.model.channel_id !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    templates() {
      return this.state.templates;
    },
    notificationTypes() {
      return this.state.notificationTypes;
    },
    notificationTypesById() {
      return _.keyBy(this.notificationTypes, "id");
    },
    optionalVariables() {
      let variables = [];
      const notificationType =
        this.notificationTypesById[this.model.notification_type_id];
      if (notificationType) {
        if (notificationType.variables) {
          variables = notificationType.variables.split(",");
        }
      }
      return variables;
    },
    requiredVariables() {
      let variables = [];
      const notificationType =
        this.notificationTypesById[this.model.notification_type_id];
      if (notificationType) {
        if (notificationType.required_variables) {
          variables = notificationType.required_variables.split(",");
        }
      }
      return variables;
    },
    models() {
      return this.$store.state.global.models;
    },
    filteredTemplates() {
      if (this.model.header_id) {
        this.model.header_id = "";
      }
      if (this.model.footer_id) {
        this.model.footer_id = "";
      }
      if (this.model.background_id) {
        this.model.background_id = "";
      }
      return _.filter(this.models, (t) => {
        return (
          t.description == this.model.description &&
          t.application_id == this.model.application_id
        );
      });
    },
  },
  methods: {
    async save_template() {
      const temp = { ...this.model };
      const submit = async () => {
        temp.variables = this.get_variables(temp.html).join(",");
        temp.html = await this.mount_model();
        this.modalLoadingMsg = "Aguarde, enviando...";
        this.$refs.modalLoading.open();
        if (!this.editing) {
          const response = await this.$store.dispatch(
            "global/save_model",
            temp
          );
          this.$refs.modalLoading.close();
          console.log("response", response);
          if (response) {
            this.$refs.modalSuccess.open();
          } else {
            this.$refs.modalError.open();
          }
        } else {
          const response = await this.$store.dispatch(
            "global/update_model",
            temp
          );
          this.$refs.modalLoading.close();
          console.log("response", response);
          if (response) {
            this.$refs.modalSuccess.open();
          } else {
            this.$refs.modalError.open();
          }
        }
      };
      const { html, design } = await this.$refs.mailEditor.save_design();
      temp.html = html;
      temp.design = JSON.stringify(design);
      if (temp.notification_type_id) {
        const { invalid, required } = this.check_tag(
          temp.html,
          this.optionalVariables,
          this.requiredVariables
        );
        if (invalid.length > 0 || required.length > 0) {
          this.$refs.modalInvalidVariables.open(required, invalid);
        } else {
          submit();
        }
      } else {
        submit();
      }
    },
    async mount_model() {
      const header = _.filter(this.templates, (t) => {
        return t.id == this.model.header_id;
      });
      const footer = _.filter(this.templates, (t) => {
        return t.id == this.model.footer_id;
      });

      const headerDesign = JSON.parse(header[0].design);

      const footerDesign = JSON.parse(footer[0].design);

      const template = await this.$refs.mailEditor.getDesign();

      this.designFull = [...headerDesign, ...template, ...footerDesign];

      const html = await this.$refs.html_generator.loadDesign();

      return html;
    },
    async show_preview() {
      const html = await this.mount_model();
      this.previewHtml = html;
      this.$refs.previewHtml.open();
    },
  },
  mounted() {
    const modelId = this.$route.params.template_id;
    if (modelId) {
      this.editing = true;
      this.$refs.modalLoading.open();
      const modelsById = _.keyBy(this.state.models, "id");
      const active = modelsById[modelId];
      this.model = { ...active };
      this.model.design = JSON.parse(this.model.design);
      this.modalLoadingMsg = "Aguarde, montando dados";
      this.showEditor = true;
      setTimeout(() => {
        this.$refs.modalLoading.close();
        console.log(this.model);
      }, 3000);
    }
  },
};
</script>

<style>
</style>